import { getNumberOfCharacterClasses } from 'SourceUtil/Validator/Validator';

export * from 'SourceUtil/Validator/Validator';

/** @namespace Ennero/Util/Validator/validatePassword */
export const validatePassword = (value, range, minCharacter) => {
    if (value.length === 0 || !range) {
        return true;
    }

    if (range.min && value.length < range.min) {
        return __('Minimum %s characters!', range.min);
    }

    if (range.max && value.length > range.max) {
        return __('Maximum %s characters!', range.max);
    }

    if (!Number(/[a-z]+/.test(value))) {
        return __('Minimum 1 lowercase letter!', range.max);
    }

    if (!Number(/[A-Z]+/.test(value))) {
        return __('Minimum 1 uppercase letter!', range.max);
    }

    // @, #, $, %, ^, &, +, =, or !
    if (!Number(/[@#$%^&+=!]+/.test(value))) {
        return __('Minimum 1 special character!', range.max);
    }

    if (!Number(/[0-9]+/.test(value))) {
        return __('Minimum 1 number!', range.max);
    }

    const counter = getNumberOfCharacterClasses(value);

    if (counter < Number(minCharacter)) {
        return (
            __(
                'Minimum of different classes of characters in password is %s. ',
                minCharacter
            ) +
            __(
                'Classes of characters: Lower Case, Upper Case, Digits, Special Characters.'
            )
        );
    }

    return true;
};
